<template>
	<div id="userlogin" class="boxsty login">
		<div class="loginbox">
			<div class="title"><div>赤道猩销售管理系统</div></div>
			<div class="radiobox">
				<img @click="changeType()" v-if="codeType==1" class="radioicon" src="@/assets/icon-loginCodeWechat.png">
				<img @click="changeType()" v-if="codeType==2" class="radioicon" src="@/assets/icon-loginAccount.png">
			</div>
		  
		  
			<div class="codebox" v-if="codeType==2">
				<div class="accountList" v-if="accountShow">
					<div class="box" v-for="(item,index) in accountList" :key="index">
						<img class="icon" src="@/assets/login/logo.png">
						<div class="text">{{item.shop_name}}</div>
						<el-button type="primary" size="small" @click="accountLogin(item)">登录</el-button>
					</div>
				</div>
				<div v-else style="display: flex;justify-content: center;height: 396px;">
					<div id="wechatCodeBox" style="height: 396px;overflow: hidden;"></div>
				</div>
			</div>
		  
			<div v-if="codeType==1">
				<div class="inputbox">
					<div class="box">
						<el-input ref="account" v-model="phone" :disabled="loading" type="text" class="input" placeholder="请输入账号" />
					</div>
				</div>
				
				<div class="inputbox">
					<div class="box">
						<el-input ref="password" v-model="password" :disabled="loading" type="password" class="input" placeholder="请输入密码" />
					</div>
				</div>
			  
			   <div class="inputbox codebox">
				  <div class="box">
					<el-input v-model="code" type="text" style="width: 400px;" class="input" placeholder="请输入验证码" />
					<el-button v-if="!codeshow" type="primary" class="codeimg"  @click="getcode">获取验证码</el-button>
					<div v-else class="codeimg success">{{ codetime }}秒后获取</div>
				  </div>
				</div>
			  
				<el-button class="loginbtn" :loading="loading"  style="width:100%;margin-bottom:30px;background-color: #1989fa;" size="mini" @click.native.prevent="login()">登 录</el-button>
			</div>
		  
		</div>
		
	</div>
</template>

<script>
import { eltips } from '@/util/util';
import {resetRouter} from '@/router/index.js'
import {timLogin} from '@/util/tim.js'
export default {
	name: 'login',
	data() {
		return {
			phone: '',
			password: '',
			code: '',
			codeshow: false,
			codetime: 59,
			userlogin: '',
			loading:false,
			codeType:1,
			accountList:[],
			accountShow:false
		};
	},
	created() {
		this.userlogin = this.$store.state.userlogin;
	},
	computed:{
		wechatCode(){
			return this.$store.state.wechatCode
		}
	},
	watch:{
		wechatCode:{
			immediate:true,
			handler(code){
				if(code){
					this.codeType = 2
					this.$nextTick(()=>{
						this.getWechatCode()
						this.wechatLogin(code)
					})
				}
			}
		}
	},
	methods: {
		//选择账号登录
		accountLogin(item){
			this.loading = true
			this.$store.commit('changeWechatCode', null)
			sessionStorage.setItem('token', item.token);
			this.getInfo();
		},
		//微信登录
		wechatLogin(code){
			this.loading = true
			sessionStorage.setItem('guardName','main_admin')
			this.axios.post('/api/main/Auth/Login',{code:code}).then(res=>{
				if(res.code==200){
					if(res.data.length==1){
						this.$store.commit('changeWechatCode', null)
						sessionStorage.setItem('token', res.data[0].token);
						this.getInfo();
					}else{
						this.loading = false
						this.accountList = res.data
						this.accountShow = true
					}
				}else{
					this.loading = false
					eltips(res.msg,'error')
				}
			})
		},
		changeType(){
			if(this.codeType==1){
				this.codeType = 2
				this.$nextTick(()=>{
					if(!this.accountShow) this.getWechatCode()
				})
			}else{
				this.codeType = 1
			}
		},
		getWechatCode(){
			let obj = new WxLogin({
				self_redirect:false,
				id:"wechatCodeBox",
				appid: "wx3e040eb946867e4d", 
				scope: "snsapi_login", 
				state:"loginCode",
				redirect_uri: encodeURI(`https://sale.cd-star.com/login`),
				href:'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDI4MHB4O30KLmltcG93ZXJCb3ggLmluZm8ge3dpZHRoOiAyODBweDt9Ci5pbXBvd2VyQm94IC5zdGF0dXMge3RleHQtYWxpZ246IGNlbnRlcjt9'
			});
		},
		//登录
		login() {
			this.loading = true
			let data = {
				username:this.phone,
				password:this.password,
				send_code:this.code,
			};
			sessionStorage.setItem('guardName','main_admin')
			this.axios.post('/api/main/Admin/login', data).then(res => {
				if (res.code == 200) {
					sessionStorage.setItem('token', res.data);
					sessionStorage.removeItem('role_id')
					this.getInfo();
				}else {
					this.loading = false
					eltips(res.msg, 'error');
				}
			});
		},
		//获取信息
		getInfo() {
			this.axios.get('/api/me',{params:{guard_name:'main_admin'}}).then(res => {
				if (res.code == 200) {
					sessionStorage.setItem('userinfo', JSON.stringify(res.data));
					this.getimtoken()
					this.getmeuns();
				} else {
					this.loading = false
					eltips(res.msg, 'error');
				}
			});
		},
		// 获取im的token
		getimtoken(){
			this.axios.get('/api/orange_manage/user/imUserSig',{params:{guard_name:'main_admin'}}).then(res=>{
				if(res.code==200){
					sessionStorage.setItem('IMtoken',res.data)
					if(!this.$store.sdk_ready) timLogin();
				}
			})
		},
		//获取菜单
		getmeuns() {
			this.axios.get('/api/get_menu').then(res => {
				this.loading = false
				eltips('登录成功', 'success');
				if(res.data.length==0){
					return
				}
				resetRouter()
				this.$store.commit('changeRouteadds', 0)
				sessionStorage.removeItem('routestext')
				sessionStorage.removeItem('routes')
				sessionStorage.setItem('routes',JSON.stringify(res.data))
				this.$router.replace({ path: '/index' }).catch(err=>{});
			});
		},
		//获取验证码
		getcode() {
			if(!this.phone) return eltips('请输入账号','error');
			
			this.axios.post('/api/main/Admin/sendCode',{username:this.phone}) .then(res => {
				if (res.code == 200) {
					eltips(res.msg, 'success');
					this.codeshow = true;
					let autocode = setInterval(() => {
						if (this.codetime > 1) {
							this.codetime--;
						} else {
							this.codeshow = false;
							this.codetime = 59;
							clearInterval(autocode);
						}
					}, 1000);
				} else {
					eltips(res.msg, 'error');
				}
			});
		}
	}
};
</script>

<style>
#userlogin .el-input__inner {
	/* border: none !important; */
	/* border-bottom: 1px solid #dcdee0 !important; */
	height: 48px !important;
	line-height: 48px !important;
	/* padding: 0 !important; */
	/* border-radius: 0 !important; */
}
#userlogin .el-input__inner:focus {
	border-color: #3773da !important;
}
</style>
<style lang="less" scoped>
	
	.loginbox {
		// width: 40%;
		border-radius: 12px;
		padding: 35px 60px;
		background: rgba(255, 255, 255, 0.8);
		width: 550px;
		position: relative;
		overflow: hidden;
		.title {
			color: #1989fa;
			font-size: 30px;
			text-align: center;
			div {
				margin-bottom: 8px;
			}
		}
		.radiobox{
			position: absolute;
			right: 0;
			top: 0;
			width: 0;      
			height: 0;      
			border-top: 90px solid rgb(25, 137, 250);
			border-left: 90px solid transparent;
			.radioicon{
				width: 40px;
				height: 40px;
				position: absolute;
				right: 5px;
				top: -80px;
				cursor: pointer;
			}
		}
		.inputbox {
			margin-top: 40px;
			.box {
				position: relative;
				display: flex;
				justify-content: space-between;
				// padding: 0 20px;
				// background: #e8e8e8;
				.codeimg {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 150px;
					text-align: center;
					background: #1989fa;
					color: #ffffff;
					border-radius: 4px;
					cursor: pointer;
					margin-left: 10px;
				}
				.success {
					background: rgba(255, 255, 255, 0) !important;
					color: #999999;
					border: 1px solid #999999;
					cursor: default;
				}
			}
			.error {
				color: #ff0000;
				font-size: 18px;
				margin-top: 8px;
			}
		}
		.loginbtn {
			margin-top: 40px;
			width: 100%;
			line-height: 54px;
			text-align: center;
			font-size: 16px;
			color: #ffffff;
			cursor: pointer;
			-moz-user-select: none; /*火狐*/
			-webkit-user-select: none; /*webkit浏览器*/
			-ms-user-select: none; /*IE10*/
			-khtml-user-select: none; /*早期浏览器*/
			user-select: none;
		}
	}
	
</style>
