<template>
	<div class="container">
		<div class="box">
			<userlogin v-if="$store.state.userlogin == 1"></userlogin>
		</div>
		<div class="boxstys top">
			<div class="left">
				<img class="logo" src="@/assets/login/logo.png">
				<span class="text">赤道猩</span>
			</div>
		</div>
		<div class="boxstys bottom">Copyright2018-{{year}} 四川赤道猩数字软件科技有限公司 蜀ICP备2023004506号-2</div>

	</div>
</template>

<script>
	import userlogin from '@/components/login/login.vue';
	export default {
		components: { userlogin },
		name: 'login',
		data() {
			return {
				year:new Date().getFullYear(),
				phone: '',
				password: '',
				code: '',
				logintype: 1,
				codeshow: false,
				codetime: 59,
			};
		},
		created() {
			console.log(11)
		},
		methods: {
		}
	};
</script>

<style scoped lang="less">
	
	.container{
		position: relative;
		background: url('../../assets/images/logobg1.png');
		background-size: 100% 100%;
		height: calc(100vh) !important;
		overflow: hidden;
	}
	
	.box {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	}


	.bottom {
		bottom: 0;
		color: #ffffff;
		text-align: center;
		line-height: 100px;
		font-size: 14px;
	}
	
	.boxstys {
		width: 100%;
		height: 100px;
		background: rgba(0, 0, 0, 0.5);
		position: fixed;
		left: 0;
	}

	.top {
		top: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 20%;
		height: 66px;

		.left {
			display: flex;
			align-items: center;

			.logo {
				width: 40px;
				height: 40px;
				border-radius: 6px;
			}

			.text {
				font-size: 28px;
				font-weight: bold;
				margin-left: 12px;
				color: #1989fa;
			}
		}

		.right {
			width: 66px;
			height: 66px;
			background: rgba(255, 255, 255, 0.2);
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 16px;
			color: #ffffff;
		}
	}
</style>

